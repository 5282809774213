import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import cn from 'classnames';

import { ROUTES } from '_constants';

import { Page } from 'components/_shared/Page';
import { SocialShare } from 'components/_shared/Social';

import { parseMarkdown } from 'utils';
import { useStrapiCommonContent } from 'hooks';

export default function NewsPost(props) {
  const { pageContext: post } = props;
  const commonContent = useStrapiCommonContent();
  const excerpt = parseMarkdown(post.starting_body?.data?.starting_body);

  const renderBackToNewsLink = className => (
    <div className={cn('flex max-w-2xl w-full', className)}>
      <div className='mr-2 text-base lg:text-lg rotate-180'>
        {commonContent.arrow}
      </div>
      <Link
        className='max-w-xl text-base lg:text-lg hover:underline'
        to={ROUTES.NEWS}
      >
        {commonContent.back_to_news}
      </Link>
    </div>
  );

  const renderPressContacts = (
    <>
      <h4 className='text-2xl font-medium'>Press Contacts</h4>

      {post.contact_name && (
        <h5 className='mt-6 text-base lg:text-lg'>{post.contact_name}</h5>
      )}

      {post.contact_email && (
        <a
          className='text-base text-gray-400 hover:underline lg:text-lg'
          href={`mailto:${post.contact_email}`}
        >
          {post.contact_email}
        </a>
      )}

      {post.contact_number && (
        <a
          className='block text-base text-gray-400 lg:text-lg'
          href={`tel:${post.contact_number}`}
        >
          {post.contact_number}
        </a>
      )}
    </>
  );

  return (
    <Page
      className='page-news-post'
      meta={{
        title: post.title,
        description: excerpt,
        image: post.banner?.url
      }}
    >
      <div className='header-spacer h-24' />
      <article>
        <div className='w-full max-w-devrev-extra mx-auto flex flex-col items-center md:items-start pt-6 px-3 md:px-6 relative lg:pt-12 lg:flex-row'>
          <div className='flex md:hidden w-full flex-shrink-0'>
            {renderBackToNewsLink('pb-4')}
          </div>
          <div>
            <header className='w-full md:hidden flex flex-col relative'>
              <h1 className='text-2xl leading-tight'>{post.title}</h1>
              <div className='flex mt-4 flex-wrap items-center md:items-start justify-between	flex-row md:flex-col'>
                {post.news_date && (
                  <time
                    className='text-lg md:text-lg lg:text-xl leading-tight text-gray-400 mr-4 mb-6'
                    dateTime={post.news_date}
                  >
                    {post.news_date}
                  </time>
                )}

                <SocialShare
                  className='mb-4'
                  title={post.title}
                  description={excerpt}
                />
              </div>
            </header>
            {post.banner?.localFile && (
              <div className='w-full'>
                <div className='w-full bg-cover bg-center mb-8 lg:mb-12'>
                  <GatsbyImage
                    className='w-full'
                    image={getImage(post.banner.localFile)}
                    alt={post.title}
                  />
                </div>
              </div>
            )}
            <div className='flex mt-6 flex-col md:flex-row'>
              <div className='md:w-1/3 w-full md:mr-6'>
                <header className='w-full hidden md:flex flex-col relative'>
                  {renderBackToNewsLink('pb-4')}

                  <h1 className='text-lg md:text-2xl lg:text-4xl leading-tight'>
                    {post.title}
                  </h1>
                  <div className='flex mt-4 flex-wrap items-center md:items-start justify-between	flex-row md:flex-col'>
                    {post.news_date && (
                      <time
                        className='text-lg md:text-lg lg:text-xl leading-tight text-gray-400 mr-4 mb-6'
                        dateTime={post.news_date}
                      >
                        {post.news_date}
                      </time>
                    )}

                    <SocialShare
                      className='mb-4'
                      title={post.title}
                      description={excerpt}
                    />
                  </div>
                </header>
              </div>
              <main className='w-full'>
                <div className='text-lg md:text-xl lg:text-2xl leading-tight'>
                  {parseMarkdown(post.news_body?.data?.news_body)}
                </div>
                <hr className='my-12' />
                {renderPressContacts}
              </main>
            </div>
          </div>
        </div>
      </article>
    </Page>
  );
}
